// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-list-tsx": () => import("../src/templates/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-about-ths-tsx": () => import("../src/pages/aboutTHS.tsx" /* webpackChunkName: "component---src-pages-about-ths-tsx" */),
  "component---src-pages-archive-tsx": () => import("../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-buddies-tsx": () => import("../src/pages/buddies.tsx" /* webpackChunkName: "component---src-pages-buddies-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-jsx": () => import("../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-guide-tsx": () => import("../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-how-reception-works-tsx": () => import("../src/pages/howReceptionWorks.tsx" /* webpackChunkName: "component---src-pages-how-reception-works-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-tsx": () => import("../src/pages/join-us.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-maps-tsx": () => import("../src/pages/maps.tsx" /* webpackChunkName: "component---src-pages-maps-tsx" */),
  "component---src-pages-mission-vision-tsx": () => import("../src/pages/mission&vision.tsx" /* webpackChunkName: "component---src-pages-mission-vision-tsx" */),
  "component---src-pages-news-tsx": () => import("../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-newstudents-tsx": () => import("../src/pages/newstudents.tsx" /* webpackChunkName: "component---src-pages-newstudents-tsx" */),
  "component---src-pages-partners-tsx": () => import("../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pm-tsx": () => import("../src/pages/pm.tsx" /* webpackChunkName: "component---src-pages-pm-tsx" */),
  "component---src-pages-pt-tsx": () => import("../src/pages/pt.tsx" /* webpackChunkName: "component---src-pages-pt-tsx" */),
  "component---src-pages-team-tsx": () => import("../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

